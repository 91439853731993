import './sass/main.scss';
import 'bootstrap';
import 'shifty';
import 'hammerjs';
import 'imagesloaded';
import 'screenfull';
import 'nanogallery2/src/jquery.nanogallery2.core.js';
import 'nanogallery2/dist/css/nanogallery2.min.css';
import 'font-awesome/css/font-awesome.css';

import favicon from './assets/favicon.ico';

import imgn0 from './assets/gallery/n0.jpg?sizes[]=420,sizes[]=1920';
import imgn1 from './assets/gallery/n1.jpg?sizes[]=420,sizes[]=1920';
import imgn2 from './assets/gallery/n2.jpg?sizes[]=420,sizes[]=1920';
import img1 from './assets/gallery/1.jpg?sizes[]=420,sizes[]=1920';
import img2 from './assets/gallery/2.jpg?sizes[]=420,sizes[]=1920';
import img3 from './assets/gallery/3.jpg?sizes[]=420,sizes[]=1920';
import img4 from './assets/gallery/4.jpg?sizes[]=420,sizes[]=1920';
import img5 from './assets/gallery/5.jpg?sizes[]=420,sizes[]=1920';
import img6 from './assets/gallery/6.jpg?sizes[]=420,sizes[]=1920';
import img7 from './assets/gallery/7.jpg?sizes[]=420,sizes[]=1920';
import img8 from './assets/gallery/8.jpg?sizes[]=420,sizes[]=1920';
import img9 from './assets/gallery/9.jpg?sizes[]=420,sizes[]=1920';
import img10 from './assets/gallery/10.jpg?sizes[]=420,sizes[]=1920';
import img11 from './assets/gallery/11.jpg?sizes[]=420,sizes[]=1920';
import img12 from './assets/gallery/12.jpg?sizes[]=420,sizes[]=1920';
import img13 from './assets/gallery/13.jpg?sizes[]=420,sizes[]=1920';
import img14 from './assets/gallery/14.jpg?sizes[]=420,sizes[]=1920';
import img15 from './assets/gallery/15.jpg?sizes[]=420,sizes[]=1920';
import img16 from './assets/gallery/16.jpg?sizes[]=420,sizes[]=1920';
import img17 from './assets/gallery/17.jpg?sizes[]=420,sizes[]=1920';
import img18 from './assets/gallery/18.jpg?sizes[]=420,sizes[]=1920';
import img19 from './assets/gallery/n19.jpg?sizes[]=420,sizes[]=1920';
import img20 from './assets/gallery/n20.jpg?sizes[]=420,sizes[]=1920';
import img21 from './assets/gallery/n21.jpg?sizes[]=420,sizes[]=1920';

$(document).ready(function () {
  $('[data-toggle="popover"]').popover();

  $(document).on('click', 'a.nav-link', function (event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 50
    }, 500);
  });

  function getImageGalleryData(img, title = '') {
    let imgInfo = {};
    $.each(img.images, function(ind, imgData) {

      if (ind === 0) {
        imgInfo.srct = imgData.path;
      }
      else if (ind === 1) {
        imgInfo.src = imgData.path;
      }
    });

    imgInfo.title = title;

    return imgInfo;
  }

  $('#ngy2p').nanogallery2({
    "itemsBaseURL": "",
    "thumbnailWidth": "auto",
    "thumbnailHeight": "250",
    "thumbnailBorderVertical": 0,
    "thumbnailBorderHorizontal": 0,
    "thumbnailHoverEffect2": "imageScaleIn80",
    "thumbnailAlignment": "justified",
    "displayBreadcrumb": false,
    items: [
      getImageGalleryData(imgn0),
      getImageGalleryData(imgn1),
      getImageGalleryData(imgn2),
      getImageGalleryData(img1),
      getImageGalleryData(img2),
      getImageGalleryData(img3),
      getImageGalleryData(img4),
      getImageGalleryData(img5),
      getImageGalleryData(img6),
      getImageGalleryData(img7),
      getImageGalleryData(img8),
      getImageGalleryData(img9),
      getImageGalleryData(img10),
      getImageGalleryData(img11),
      getImageGalleryData(img12),
      getImageGalleryData(img13),
      getImageGalleryData(img14),
      getImageGalleryData(img15),
      getImageGalleryData(img16),
      getImageGalleryData(img17),
      getImageGalleryData(img18),
      getImageGalleryData(img19),
      getImageGalleryData(img20),
      getImageGalleryData(img21)
    ]
  });

  //$('[data-spy="scroll"]').scrollspy({ target: '#navbar-main' });
});